import React, { Component } from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";

export const query = graphql`
  fragment Meta on MarkdownRemark {
    frontmatter {
      meta {
        title
        description
        noindex
        canonicalLink
        absoluteImageUrl
        type
      }
      date(formatString: "MMMM Do, YYYY")
    }
  }
`;

export default class Meta extends Component {
  render() {
    const {
      title,
      url,
      description = "Museum-quality posters made on thick matte paper. Add a wonderful accent to your room and office with these posters that are sure to brighten any environment.",
      date,
      absoluteImageUrl = "https://www.lweart.com/images/artist.jpg",
      twitterSiteAccount,
      twitterCreatorAccount,
      noindex,
      canonicalLink,
      type,
      siteTitle,
      googleTrackingId,
    } = this.props;

    return (
      <Helmet htmlAttributes={{ lang: "en" }}>
        {title && <title>{title}</title>}
        {title && <meta property="og:title" content={title} />}
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        {url && <meta property="og:type" content={type} />}
        {url && <meta property="og:url" content={url} />}
        {noindex && <meta name="robots" content="noindex" />}
        {url && <link rel="canonical" href={url} />}
        <meta name="theme-color" content="#fff" />
        <meta property="og:url" content={url} />
        <meta property="og:type" content={type} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={siteTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={absoluteImageUrl} />
        <meta property="og:image:secure_url" content={absoluteImageUrl} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta property="og:image:alt" content={title} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          property="article:published_time"
          content={date || "2024-01-09"}
        />
        <meta name="content_origin" content="2024-01-09" />
        <meta name="content_updated" content="2024-01-09" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="author" content="Atlas Company" />
        <meta
          name="publish_date"
          property="og:publish_date"
          content="2024-01-09T00:00:00-0600"
        />
        <meta
          name="last_modified"
          property="article:modified_time"
          content={new Date().toISOString()}
        />

        {/* {googleTrackingId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTrackingId}`}
          />
        )}

        {googleTrackingId && (
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleTrackingId}');
            `}
          </script>
        )} */}

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: description,
          })}
        </script>
      </Helmet>
    );
  }
}
