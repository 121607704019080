import React from "react";

import styles from "./Footer.module.scss";

export default () => (
  <>
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerGrid}>
          <div>
            <img
              src="/images/logo.svg"
              style={{ width: "100px", height: "80px" }}
            />
            <p>
              All artworks you see on webshop is made by Vegard. Vegard Løwe is
              a self-taught artist whose passion for painting began at a young
              age.
            </p>
          </div>
          {/* <div>
            <ul class={styles.listUnstyled}>
              <li>
                <h4>Product</h4>
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>
              <li>
                <a href="/community/maps">Community</a>
              </li>
              <li>
                <a href="/resources">Resources</a>
              </li>
            </ul>
          </div> */}
          <div>
            {/* <ul class={styles.listUnstyled}>
              <li>
                <h4>Product</h4>
              </li>
              <li>
                <a href="/#spatial-analysis">Spatial Analysis</a>
              </li>
              <li>
                <a href="/#data">Data</a>
              </li>
              <li>
                <a href="/#collaborate">Collaborate</a>
              </li>
            </ul> */}
            <ul class={styles.listUnstyled}>
              <li>
                <h4>Collections</h4>
              </li>
              <li>
                <a href="/collections/originals/">Originals</a>
              </li>
              <li>
                <a href="/collections/prints/">Prints</a>
              </li>
              {/* <li>
                <a href="/help/">All Artworks</a>
              </li> */}
              {/* <li>
                <a href="/help/docs/">Originals</a>
              </li> */}
            </ul>
          </div>
          <div>
            <ul class={styles.listUnstyled}>
              <li>
                <h4>Information</h4>
              </li>
              <li>
                <a href="/about/">About</a>
              </li>
              <li>
                <a href="/contact/">Contact</a>
              </li>
              <li>
                <h4>Policies</h4>
              </li>
              <li>
                <a href="/refund-policy/">Refund Policy</a>
              </li>
            </ul>
          </div>
          {/* <div>
            <ul class={styles.listUnstyled}>
              <li>
                <h4>Guides</h4>
              </li>
              <li>
                <a href="/blog/get-better-at-using-color-palettes-with-choropleth-maps/">
                  Get better at using color palettes with choropleth maps
                </a>
              </li>
              <li>
                <a href="/blog/free-data-sources-for-environmental-data/">
                  Free Data Sources for Environmental Data
                </a>
              </li>
              <li>
                <a href="/blog/new-in-atlas-flexible-popups/">
                  New in Atlas: Flexible Popups
                </a>
              </li>
              <li>
                <a href="/blog/reveal-patterns-with-heatmaps/">
                  Reveal Patterns with Heatmaps
                </a>
              </li>
              <li>
                <a href="/blog/travel-time-analysis-to-analyze-locations/">
                  Travel Time Analysis to Analyze Locations
                </a>
              </li>
              <li>
                <a href="/blog/bookmarks-shortcuts-to-interesting-places/">
                  Bookmarks - Shortcuts to Interesting Places
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div class={styles.bottomFooter}>
        <div className={styles.bottomFooterContent}>
          <div>Copyright {new Date().getFullYear()} © LWE Artworks.</div>
          <div className={styles.footerLinks}>
            <a href="/privacy-policy/">Privacy Policy</a>
            <a href="/cookie-policy/">Cookie Policy</a>
          </div>
        </div>
      </div>
    </div>
  </>
);
