import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Meta from "./Meta";
import Nav from "./Nav";
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";
import "modern-normalize/modern-normalize.css";
import "../../styles/global.scss";
import "../../fonts/fonts.css";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

export default ({ children, meta, title, noindex }) => {
  const location = useLocation();

  const handleAcceptCookie = () => {
    initializeAndTrack(location);
  };

  const handleDeclineCookie = () => {
    window[`ga-disable-Y381EDKYT5`] = true;
  };

  useEffect(() => {
    window[`ga-disable-Y381EDKYT5`] = true;
  }, []);

  const url = `https://lweart.com${location.pathname}`;

  useEffect(() => {
    if (!document) return;
    document.body.scrollTop = 0;
  }, [location.pathname]);

  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
            data.settingsYaml || {},
          subNav = {
            posts: data.allPosts.hasOwnProperty("edges")
              ? data.allPosts.edges.map((post) => {
                  return { ...post.node.fields, ...post.node.frontmatter };
                })
              : false,
          };

        return (
          <Fragment>
            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
              url={url}
              noindex={noindex}
            />

            {/* <CookieConsent
              enableDeclineButton
              onAccept={handleAcceptCookie}
              onDecline={handleDeclineCookie}
              location="bottom"
              containerClasses="cookieContainer"
              buttonText="Continue"
              cookieName="gdpr-google-analytics"
              declineButtonClasses="cookie-declinebutton"
              contentClasses="cookie-content"
              buttonClasses="cookie-button"
              expires={150}
            >
              <div className="container">
                <p>
                  We use cookies to improve user experience and analyze website
                  traffic. By continuing to the site, you consent to store all
                  the technologies described in our{" "}
                  <a href="/cookie-policy">Cookie Policy.</a>{" "}
                </p>
              </div>
            </CookieConsent> */}
            <div
              style={{
                height: "calc(100vh - 64px)",
              }}
            >
              <Fragment>{children}</Fragment>
              <Nav />
              <Footer />
            </div>
          </Fragment>
        );
      }}
    />
  );
};
