import React, { Fragment } from "react";
import styles from "./Button.module.scss";

export default ({
  variant = "primary",
  type = "button",
  href,
  external,
  size,
  fullWidth,
  children,
  onClick,
  rightIcon,
  disabled,
  leftIcon,
}) => {
  return href ? (
    <a
      href={href}
      className={`${styles.button} ${variant === "secondary" &&
        styles.secondary} ${variant === "outline" &&
        styles.outline} ${variant === "minimal" && styles.minimal} ${variant ===
        "inverse" && styles.inverse} ${size === "xl" &&
        styles.xl} ${fullWidth && styles.fullWidth} ${disabled &&
        styles.disabled}`}
      target={external ? "_blank" : ""}
      type={type}
    >
      {leftIcon && (
        <span className={`${styles.icon} ${leftIcon && styles.left}`}>
          {leftIcon}
        </span>
      )}
      {children}
      {rightIcon && <span className={styles.icon}>{rightIcon}</span>}
    </a>
  ) : (
    <button
      className={`${styles.button} ${variant === "secondary" &&
        styles.secondary} ${variant === "outline" &&
        styles.outline} ${variant === "minimal" && styles.minimal} ${size ===
        "xl" && styles.xl} ${fullWidth && styles.fullWidth} ${disabled &&
        styles.disabled}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && (
        <span className={`${styles.icon} ${leftIcon && styles.left}`}>
          {leftIcon}
        </span>
      )}
      {children}
      {rightIcon && <span className={styles.icon}>{rightIcon}</span>}
    </button>
  );
};
