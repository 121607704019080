import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { Menu, X } from "react-feather";
import Logo from "./Logo";
import styles from "./Nav.module.scss";
import Button from "./Button";
import {
  AcademicCapIcon,
  ArrowRightIcon,
  BookOpenIcon,
  LifebuoyIcon,
  MapIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { FaChevronDown } from "react-icons/fa6";
import { set } from "lodash";

const Navigation = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const [active, setActive] = useState(false);
  const [flyout, setFlyout] = useState(null);
  const [navHover, setNavHover] = useState(false);
  const ref = useRef(null);
  const [mobileActive, setMobileActive] = useState(false);

  useEffect(() => {
    setCurrentPath(location?.pathname);
  }, []);

  // const handleMenuToggle = () => setActive(!active)
  const handleMenuToggle = () => setActive(!active);

  const handleMobileToggle = () => setMobileActive(!mobileActive);

  const handleLinkClick = () => [active && handleMenuToggle()];

  const handleFlyout = (value) => {
    let flyout = null;
    if (value === "resources") {
      flyout = {
        description: "resources",
        position: "100px",
        links: [
          {
            title: "Guides",
            icon: <AcademicCapIcon width={30} />,
            description: "Learn the basics",
            link: "/help/guides/",
          },
          {
            title: "Documentation",
            icon: <BookOpenIcon width={30} />,
            description: "Atlas Docs",
            link: "/help/docs/",
          },
          {
            title: "Blog",
            icon: <PencilSquareIcon width={30} />,
            description: "Latest updates and news",
            link: "/resources/",
          },
          {
            title: "Help Center",
            icon: <LifebuoyIcon width={30} />,
            description: "Curated guides and FAQs",
            link: "/help/",
          },
        ],
      };
    }
    setFlyout(flyout);
  };

  const NavLink = ({ to, className, children, ...props }) => (
    <Link
      to={to}
      className={className}
      onClick={active && handleLinkClick}
      {...props}
    >
      {children}
    </Link>
  );

  const [scrolled, setScrolled] = useState(false);
  const [onTop, setOnTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollY >= 800) {
        setScrolled(true); // visible
      } else {
        setScrolled(false); // not visible
      }
      // lastScrollTop.current = scrollY <= 0 ? 0 : scrollY;
      scrollY === 0 ? setOnTop(true) : setOnTop(false);
    };

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (currentPath === "/" && !isMobile) {
      setScrolled(false);
      document.body.addEventListener("scroll", handleScroll);
    } else {
      setScrolled(true);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentPath]);

  return !mobileActive ? (
    <div>
      {/* <div className={`${styles.announcement} ${scrolled && styles.scrolled}`}>
        30% OFF ALL ART THIS WEEK
      </div> */}
      <nav
        className={`${styles.nav} ${active && styles.active} ${mobileActive &&
          styles.active} ${scrolled && styles.scrolled}`}
        onMouseLeave={() => setFlyout(null)}
      >
        <div
          className={`${styles.navContainer} ${scrolled && styles.scrolled}`}
        >
          <Link to="/" onClick={handleLinkClick}>
            <Logo />
          </Link>
          <div className={styles.navLinks}>
            <NavLink
              to="/collections/originals/"
              className={`${styles.navLink} ${"/collections/originals/" ===
                currentPath && styles.active}`}
              onMouseOver={() => handleFlyout(null)}
            >
              Originals
            </NavLink>
            <NavLink
              to="/collections/prints/"
              className={`${styles.navLink} ${"/collections/prints/" ===
                currentPath && styles.active}`}
              onMouseOver={() => handleFlyout(null)}
            >
              Prints
            </NavLink>
            <NavLink
              to="/contact/"
              className={`${styles.navLink} ${"/contact/" === currentPath &&
                styles.active}`}
              onMouseOver={() => handleFlyout(null)}
            >
              Contact
            </NavLink>
            <NavLink
              to="/about/"
              className={`${styles.navLink} ${"/about/" === currentPath &&
                styles.active}`}
              onMouseOver={() => handleFlyout(null)}
            >
              About
            </NavLink>

            {/* <NavLink
              to="/resources/"
              className={`${styles.navLink} ${("/resources/" === currentPath ||
                flyout?.description === "resources") &&
                styles.active}`}
              onMouseOver={() => handleFlyout("resources")}
            >
              Resources <FaChevronDown width={16} />
            </NavLink> */}
          </div>

          <button
            className={styles.menuButton}
            onClick={() => handleMobileToggle()}
          >
            {active ? (
              mobileActive ? (
                <X />
              ) : (
                <Menu />
              )
            ) : mobileActive ? (
              <X />
            ) : (
              <Menu />
            )}
          </button>
          {flyout !== null && (
            <div
              className={styles.flyoutMenu}
              style={{ left: flyout.position }}
            >
              <p className={styles.flyoutDescription}>{flyout.description}</p>
              <div className={styles.flyoutGrid}>
                {flyout.links.map((link) => (
                  <NavLink to={link.link}>
                    <div className={styles.flyoutLink}>
                      <div className={styles.flyoutLinkSymbol}>{link.icon}</div>
                      <div className={styles.flyoutLinkContent}>
                        <h5>{link.title}</h5>
                        <p>{link.description}</p>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  ) : (
    <div>
      <nav className={`${styles.nav} ${mobileActive && styles.active}`}>
        <div className={styles.navContainer}>
          <Link to="/" onClick={handleLinkClick}>
            {flyout != "none" || active ? (
              mobileActive ? (
                <Logo />
              ) : (
                <Logo />
              )
            ) : mobileActive ? (
              <Logo />
            ) : (
              <Logo />
            )}
          </Link>
          <div className={styles.navLinks}>
            <NavLink
              to="/"
              className={`${styles.navLink} ${"/" === currentPath &&
                styles.active}`}
            >
              Home
            </NavLink>
            <NavLink
              to="/collections/originals/"
              className={`${styles.navLink} ${"/collections/originals/" ===
                currentPath && styles.active}`}
            >
              Originals
            </NavLink>
            <NavLink
              to="/collections/prints/"
              className={`${styles.navLink} ${"/collections/prints/" ===
                currentPath && styles.active}`}
            >
              Prints
            </NavLink>
            <NavLink
              to="/contact/"
              className={`${styles.navLink} ${"/contact/" === currentPath &&
                styles.active}`}
            >
              Contact
            </NavLink>
            <NavLink
              to="/about/"
              className={`${styles.navLink} ${"/about/" === currentPath &&
                styles.active}`}
            >
              About
            </NavLink>
          </div>
          <button
            className={styles.menuButton}
            onClick={() => handleMobileToggle()}
          >
            {active ? (
              mobileActive ? (
                <X />
              ) : (
                <Menu />
              )
            ) : mobileActive ? (
              <X />
            ) : (
              <Menu />
            )}
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
