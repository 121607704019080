import React from "react";
import styles from "./Logo.module.scss";

export default () => (
  <div
    className={styles.Logo}
    style={{
      backgroundImage: `url(/images/logo.svg)`,
    }}
  />
);
